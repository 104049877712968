<template>
  <div class="contentView">
    <div v-if="!permission && permission === 0" class="noPower-div">
      <div class="noPower-box">
        <svg-icon icon-class="noPermission"></svg-icon>
        <div class="title-text">没有权限访问</div>
        <p class="tip-text">你可以向<span class="owner-text">{{ owner}}(所有者)</span>申请权限</p>
      </div>
    </div>
    <img v-else src="../../../public/static/img/loading.gif" alt="">
  </div>
</template>
<script>
import { getDpath, recordShare, getHavePermission } from '@/api/space'
import { newFile } from '@/api/fileApi'
import { viewLatelyList } from '@/api/template'
export default {
  data() {
    return {
      permission: null,
      owner: ''
    }
  },
  mounted() {
    this.getPermission()
  },
  methods: {
    getPermission() {
      getHavePermission({
        dpIdStr: this.$store.getters.udId.replaceAll('-', ','),
        encryptionStr: this.$route.params.pathMatch
      }).then((res) => {
        this.permission = res.data.permission
        this.owner = res.data.username
        if (this.permission && this.permission !== 0) {
          this.getDetail(this.$route.params.pathMatch)
        }
      }).catch(() => {
      })
    },
    getDetail(encryptionStr) {
      getDpath({ encryptionStr: encryptionStr }).then(res => {
        if (res.code === '000000' && res.data) {
          this.getUrl(res.data)
        }
      }).catch(err => {
        console.log(err)
      })
    },
    getUrl(data) {
      // 跳转到dzz的默认记录到最近修改
      const params = {
        chapterId: data.id,
        type: data.type,
        username: data.username,
        workcode: data.workcode
      }
      viewLatelyList(params).then(res => {
        console.log(res)
      }).catch(error => console.log(error))
      const Base64 = require('js-base64').Base64
      const urlCode = Base64.encode(sessionStorage.getItem('urlInfo'))
      let href = `${process.env.VUE_APP_PHP_BASE_API}/api.php?action=onlyOfficeEdit&path=` + data.dpath
      // 如果是自己的，会到编辑页,不是自己的到查看页并记录分享数据 (后期处理)
      if (data.type === 'newMp4') {
        newFile({
          action: 'getFileDetail',
          code: urlCode,
          path: data.dpath
        }).then((res) => {
          href = res.data.url
          if (data.isMy) {
            window.location.href = href
          } else {
            this.setRecord(data, href)
          }
        }).catch(err => {
          console.log(err)
        })
      } else {
        if (data.isMy) {
          window.location.href = href + `&code=${urlCode}`
        } else {
          href = this.permission === 3 ? href : href + `&code=${urlCode}`
          this.setRecord(data, href)
        }
      }

      // 如果是自己的，会到编辑页,不是自己的到查看页并记录分享数据 (后期处理)
    },
    setRecord(data, url) {
      const record = {
        chapterId: data.id,
        createWorkcode: data.workcode,
        encryptionStr: data.encryptionStr
      }
      recordShare(record).then(res => {

      }).catch(() => {
        // window.location.href = url
      })
      window.location.href = url
    }
  }
}
</script>
<style lang="scss" scoped>
.contentView {
  width: 100vw;
  height: 100vh;
  text-align: center;
  display: table-cell;
  vertical-align: middle;
  img {
    width: 400px;
  }
}
</style>
